<template>
  <div class="container ql-editor">
    <PublicHeader :totalName="$t('login').ageentTitle" />
    <div v-html="agreement"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PublicHeader from '@/components/publicHeader'
import userApi from '@/api/user'
export default {
  components: {
    PublicHeader,
  },
  data() {
    return {
      agreement: '',
    }
  },
  computed: {
    ...mapGetters(['lang']),
  },
  async mounted() {
    this.agreement = await userApi.agreement({ language: this.lang })
  },
}
</script>
<style>
body {
  font-size: 13px;
  color: #ffffff;
}
strong {
  font-weight: bold;
}
</style>
<style lang="stylus" scoped>
.container
  padding-top 60.5px
</style>
